import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-landing-popup',
  templateUrl: './landing-popup.component.html',
  styleUrls: ['./landing-popup.component.less']
})
export class LandingPopupComponent implements OnInit {
  @Output() closePopup = new EventEmitter<void>();
  showPopup: boolean = false;

  constructor() { }

  ngOnInit(): void {
    setTimeout(() => {
      this.showPopup = true;
    }, 500);
  }

  close() {
    this.closePopup.emit();
  }
}
