<div class="container">
    <div class="row margin-top">
        <!-- Title And Under Line -->
        <div class="col-12">
            <h1>{{'my_profile_title' | translate}}</h1>
            <div class="line"></div>
            <!-- Loader -->
            <div class="loader-position">
                <mat-spinner class="loader" [diameter]="70" *ngIf="displayLoader"></mat-spinner>
                <h1 class="error-massage pad-bot">{{errorMassage | translate}}</h1>
            </div>
        </div>
    </div>
    <div class="row mb-5 mobile-left" *ngIf="dataUserInfos">
        <!-- Full Name -->
        <div [ngClass]="{'col-12' : !cardEKO, 'col-md-6' : cardEKO}">
            <h3>{{'step_2_full_name' | translate}}</h3>
            <h2>{{dataUserInfos.FirstName +' '+ dataUserInfos.LastName}}</h2>
        </div>
        <!-- Company Name -->
        <div class="col-md-6" *ngIf="cardEKO">
            <h3>{{'my_profile_company_name' | translate}}</h3>
            <h2>{{dataUserInfos.CompanyName}}</h2>
        </div>
        <!-- Phone Number -->
        <div class="col-md-6">
            <h3>{{'step_3_phone_number' | translate}}</h3>
            <h2>{{dataUserInfos.Mobile}}</h2>
        </div>
        <!-- Email -->
        <div class="col-md-6">
            <h3>Email</h3>
            <h2>{{dataUserInfos.Email}}</h2>
        </div>
        <!-- AFM -->
        <div class="col-md-6">
            <h3>{{'step_2_afm' | translate}}</h3>
            <h2>{{dataUserInfos.TIN}}</h2>
        </div>
        <!-- Car Plate -->
        <div class="col-md-6">
            <h3>{{'step_2_car_plate' | translate}}</h3>
            <h2>{{dataUserInfos.CarPlate}}</h2>
        </div>
        <!-- Under Line -->
        <div class="col-12 mt-5">
            <div class="line"></div>
        </div>
        <!-- Number Bank Card -->
        <div id="bankCard" class="col-md-6">
            <div *ngIf="cardDetailLast4 && !cardEKO">
                <p class="cards-titles">{{'steps_add_bank_card' | translate}}</p>
                <h3>{{'bank_card_number' | translate}}</h3>
                <h2>XXXX XXXX XXXX {{cardDetailLast4}}</h2>
            </div>
            <!-- EKO Card -->
            <div *ngIf="cardEKO">
                <p class="cards-titles">{{'my_profile_eko_card' | translate}}</p>
                <h3>{{'bank_card_number' | translate}}</h3>
                <h2>XXXX XXXX XXXX {{dataUserInfos.EkoCard}}</h2>
                <!-- EKO Card Image -->
                <div class="alignment-eko-card">
                    <img class="eko-card-width" src="assets/images/EKO_Card.png" />
                </div>
            </div>
        </div>
        <!-- RFID Card -->
        <div class="col-md-6 rfid-card-mobile">
            <p class="cards-titles">{{'my_profile_rfid_card' | translate}}</p>
            <h3>{{'my_profile_number_rfid_card' | translate}}</h3>
            <h2>{{dataUserInfos.VisualCardNumber}}</h2>
            <!-- Image Card And Actice Status -->
            <div class="active-div" [ngClass]="{'blue-color' : activeUser , 'red-color': !activeUser}">
                <!-- Image Card -->
                <img [ngClass]="{'dept-image' : totalDeptCdrs > 0 && !activeUser}" src="assets/images/{{typeCard}}.png"
                    (click)="payDeptCheck()" />
                <!-- Icon -->
                <div class="padding-left">
                    <mat-icon aria-hidden="false" aria-label="Example home icon">{{activeUserIcon}} </mat-icon>
                    <p class="active">{{activeUserString | translate}}</p>
                </div>
            </div>
            <div *ngIf="hasOldCards && oldRfidCards.length">
                <div class="horizontal-line"></div>
                <div class="expansion_panel--old-cards">
                    <p class="cards-titles">{{'my_profile_old_rfid_cards' | translate}}</p>
                    <mat-accordion>
                        <mat-expansion-panel *ngFor="let oldRfid of oldRfidCards">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    <p>{{oldRfid.visualCardNumber}}</p>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="card-status">
                                <img [className]="oldRfid.isCardInactive ? oldRfid.dynamicClassNameForDeprecatedCard : ''"
                                    src='assets/images/{{oldRfid.typeCard}}.png' />
                                <div class="card-status__wrapper" *ngIf="oldRfid.isCardInactive">
                                    <p class="active red-color">{{ oldRfid.cardVerbal | translate}} </p>
                                    <p class="mark-danger active red-color">{{ 'since' |translate }}
                                        {{oldRfid.deactivatedDate | momentPipe:'DD/MM/YYYY'}}</p>
                                </div>
                                <div class="card-status__wrapper" *ngIf="!oldRfid.isCardInactive">
                                    <p class="active green-color">{{ oldRfid.cardVerbal | translate}}</p>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </div>
            </div>
        </div>
        <!-- Dept Text -->
        <div class="dept-text" *ngIf="totalDeptCdrs > 0 && !activeUser">
            <p [innerHTML]="'my_profile_dept_text_acount' | translate"></p>
        </div>
    </div>
</div>