<div class="popup-overlay" [class.show]="showPopup" (click)="close()">
  <div class="popup-content" (click)="$event.stopPropagation()">
    <div class="popup-logo"><img src="assets/images/logoSignIn2.png"></div>
    <div class="popup-text">
      <span>{{'splash_dialog' | translate}}</span>
    </div>
    <div class="popup-close" (click)="close()">
      <span>{{'gen_continue' | translate}}</span>
    </div>
  </div>
</div>
